import "./login_animation";

$(document).ready(async function() {
  waitForElm('#particles').then((elm) => {  
    $('#particles').particleground({
      dotColor: 'rgba(170,150,150,.5)',
      lineColor: 'rgba(170,150,150,.25)',
      density:5000,
      particleRadius:6,
      lineWidth:1,
      parallax:false,
      parallaxMultiplier:40,
      proximity:115,
    });
  });
})


function waitForElm(selector) {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
              observer.disconnect();
              resolve(document.querySelector(selector));
          }
      });

      // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}